<template>
  <div class="content">
    <vue-whatsapp-widget
      phoneNumber="+524421177251"
      companyName="cabo.party (Marrissa)"
      textReply="Typically replies within 10-15 mins (9am - 10pm)"
      companyLogo="https://imgix.cosmicjs.com/4674bea0-3e73-11ec-9828-0f2f00f103e0-telegram-peer-photo-size-1-5166023334515814245-1-0-0.jpg?h=50"
    />
    <div style="margin-top: -50px">
      <div
        class="header text-center"
        style="margin-bottom: -35px"
        v-if="!compact"
      >
        <br />
        <br />

        <h1 class="tulum-party-title" style="font-size: 2.6rem">CABO EVENT CALENDAR</h1>
        <h4
          class="tulum-party-subtitle-bg"
          style="font-size: 1rem; margin-top: -30px"
        >
          SAN JOSE CABO & CABO SAN LUCAS
        </h4>

        <br>

        <HeadImage

          color="#1a252b"
          :image="mainImage"  align="text-center" caption="Events You Do Want To Miss" title="Event Calendar"   @click="this.googleCalendar" ></HeadImage>

        <br>
        <br>
        <p>
          <strong>Wondering where to go?</strong>.
          <br>
          Take a look at our <strong> Dinner, Event & Party Calendar</strong>.
        </p>

        <br />
        <div class=" centered">

          <p class="centered" style="font-size: 11px">
             We got a google calendar version
            <br>
            <a @click="this.googleCalendar" class="btn-link" href="#">Google Calendar</a>
          </p>
        </div>
        <br />
        <div
          class="card text-center centered"
          style="
            background: linear-gradient(#303030, black);
            max-width: 500px;
            z-index: 1;
            width: 80%;
            margin-bottom: 50px;
            padding: 10px;
          "
        >
<!--          <p class="section-header">EVENT CALENDAR</p>-->
          <br>
          <div
            class="card"
            style="
              margin-top: 10px;
              max-width: 300px;
              margin-left: auto;
              margin-right: auto;
              left: 0;
              right: 0;
              background: linear-gradient(#303030, black);
            "
          >


            <br>
            <small>Select period you are interested on</small>
            <br>

            <VMenu
              :triggers="['click']"
              :delay="2"
              :popperHideTriggers="['click']"
              :hideTriggers="['click']"
            >
              <span class="btn btn-link" style="border: 1px solid #2eafb4"
                >{{ periodLabel }}
              </span>
              <template #popper>
                <div class="card card-bg-black" style="margin-bottom: 35px;">
                  <span @click="getPeriod('today')" class="btn-link"
                    >Today</span
                  >
                  <span @click="getPeriod('tomorrow')" class="btn-link"
                    >Tomorrow</span
                  >
                  <span @click="getPeriod('this_week')" class="btn-link"
                    >This Week</span
                  >
                  <span @click="getPeriod('next_week')" class="btn-link"
                    >Next Week</span
                  >
                  <span @click="getPeriod('this_month')" class="btn-link"
                    >This Month</span
                  >
                  <span @click="getPeriod('next_month')" class="btn-link">
                    Next Month</span
                  >
                  <span @click="googleCalendar" class="btn-link">Full Calendar</span
                  >
                </div>

              </template>
              <br>
              <br>
            </VMenu>
          </div>

          <small>All Events | Updated Daily | Locations | Start Times | Club Activity</small>

        </div>
      </div>
      <br />
      <br />
      <div class="row row-no-gutters" style="margin: 0 20px 0 20px">
        <div class="col-sm-4 offset-sm-2">
          <small class="badge-dark"
            ><strong>{{ periodLabel }}</strong>
          </small>
        </div>
        <div class="col-sm-4 offset-sm-2">
          <small class="badge-dark"
            >{{ periodParties.length }} event(s) found</small
          >
        </div>

        <div class="col-12 text-center card" v-if="periodParties.length === 0">
          <h4 class="badge-dark" style="margin-top: 90px">No event(s) found</h4>
          <small>Most events are published one or two weeks prior</small>
        </div>

        <div
          class="row"
          v-if="listView"
          :style="{background: `linear-gradient(${this.color}, black)`}"
          style="
            margin-top: 10px;
            padding: 3px;
            width: 100%;
            border: 2px solid #1d1d1d;
          "
        >
          <div
            class="col-md-8 offset-md-2 col-sm-10 offset-sm-1"
            :key="idx"
            style="
              margin-top: 10px;
              background: radial-gradient(#303030, black);
            "
            v-for="(event, idx) in periodParties"
          >
            <div v-if="periodPartiesDates[idx]">
              <p
                v-if="['month', 'year'].indexOf(period) === -1"
                style="
                  background-color: #2eafb4;
                  text-transform: uppercase;
                  font-weight: bold;
                  margin-bottom: 0;
                "
              >
                {{
                  getDay(event.metadata.date + 'T' + event.metadata.start_time)
                }}
              </p>
              <p
                v-if="['month', 'year'].indexOf(period) > -1"
                style="
                  background-color: #2eafb4;
                  text-transform: uppercase;
                  font-weight: bold;
                  margin-bottom: 0;
                "
              >
                {{
                  getDay3(event.metadata.date + 'T' + event.metadata.start_time)
                }}
              </p>
              <p
                style="
                  color: silver !important;
                  font-size: 1.4rem;
                  background-color: black;
                  font-weight: bold;
                "
              >
                {{
                  getDay2(event.metadata.date + 'T' + event.metadata.start_time)
                }}
              </p>
            </div>

            <MiniEventCard
              :event="event"
              withVenue="true"
              large="true"
              :key="event.title"
            ></MiniEventCard>

<!--            <MiniPartyCard-->
<!--              v-if="event.metadata.magicEvent"-->
<!--              :event="event"-->
<!--              large="true"-->
<!--              withVenue="true"-->
<!--              :key="event.title"-->
<!--            ></MiniPartyCard>-->
          </div>
        </div>

        <div
          class="lds-dual-ring"
          v-if="this.$store.state.status.loading"
          style="position: fixed; z-index: 9999"
        >
          LOADING
        </div>
      </div>

      <div class="row" style="margin-top: 40px">
        <div
          class="col-10 card centered text-center"
          style="background-color: black; padding: 20px"
        >
          <h4 class="section-header">PLAN AHEAD</h4>
          <img
            v-lazy="djs + '?w=180&h=200&fit=clamp'"
            height="200px"
            width="180px"
            class="centered"
          />
          <br />

          <p>
            Need some help planning or executing your plan,
            <b>drop us a line.</b> <br />Let's dive into Los Cabo Party Elite
            Scene
          </p>

          <br />

          <br />

          <div class="text-center">
            <a
              href="https://wa.me/524421177251?text=Thanks%20for%20contacting%20us!%20How%20can%20we%20help%3F"
              v-on:click="onQuickReserveClick('Party Support')"
              class="btn btn-sm btn-primary font-weight-bold d-inline-xs"
              style="
                width: 80%;

                margin-left: auto;
                margin-right: auto;
                left: 0;
                right: 0;
                padding: 10px;
                border: 1px solid silver;
              "
            >
              📲 DROP US A LINE
            </a>
            <br />
            <br />
            <br />
            <a href="mailto:bookings@cabo.party">bookings@cabo.party</a>
            <br />

            <small> We operate from 9AM - 10PM / Answer within 15 mins</small>
            <br />
            <br />
            <small style="color: #fff; font-weight: bolder">NO EXTRA FEE</small>
          </div>
        </div>
      </div>

      <div class="row" style="margin-top: 70px">
        <div class="col-sm-12">
          <h3 class="section-header text-center">You may be interested</h3>
        </div>

        <div class="col-12 text-center">
          <DynamicLinks
            :links="links"
            size="lg"
            :style="{ background: `linear-gradient(45deg, black, #3485b0 )` }"
          ></DynamicLinks>
        </div>

        <br />
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import MiniEventCard from '@/components/EventCard/MiniEventCard';
import MiniPartyCard from '@/components/EventCard/MiniPartyCard';

import { COSMIC_BASE_URL } from '@/common/request';
import DynamicLinks from '@/components/DynamicLinks';
import HeadImage from "@/components/HeadImage/HeadImage";

const VueWhatsappWidget = () =>
  import(
    /* webpackChunkName: "vue-whatsapp-widget" */ '../../components/WhatsAppWidget/Widget'
  );

export default {
  name: 'PartyCalendar',
  data: () => {
    return {
      showPalms: false,
      compact: false,
      lentesImg: "https://imgix.cosmicjs.com/d944c4a0-9cf4-11ee-93ed-8bfbf1aced8f-image.png",
      showCalendarTab: false,
      showEventsTab: true,
      period: 'week',
      periodLabel: 'this_week',
      color: '#2d6467',
      logito:
        'https://imgix.cosmicjs.com/8c49adc0-c592-11ee-8294-5bdacc8e0a52-astro-dj.jpg',
      djs: 'https://imgix.cosmicjs.com/9f39a6a0-c70a-11ee-9ce5-59949019255e-dj.png',
      periodPartiesDates: [],
      blueEyeImage:
        'https://imgix.cosmicjs.com/d81fa300-9a2a-11ee-b34c-99d1d89e42eb-eye-blue.png',
      listView: true,
      periodParties: [],
      calImage:
        'https://imgix.cosmicjs.com/00c12a20-7d7c-11ee-9baf-2960bf73c40e-image.png',
      gCalImg:
        'https://imgix.cosmicjs.com/cdf97730-803e-11ee-9ebe-eb22b0517bf0-image.png',
      mainImage:
        "https://imgix.cosmicjs.com/d9751ff0-f637-11ee-b555-0d0678c27dd7-los-cabos-drawing.png",
      bgPalm:
        'https://imgix.cosmicjs.com/0e9bd970-f811-11ec-b2b1-473235369c53-image.png',
    };
  },
  metaInfo() {
    return {
      title: `Los Cabos | Shows, Parties & Events Calendar · Tickets & Reservations`,
      link: [
        {
          rel: 'canonical',
          href: 'https://www.cabo.party/los-cabos-mexico-event-calendar',
        },
      ],
      meta: [
        {
          name: 'description',
          content: `Los Cabos Party Calendar. Upcoming Events @ Los Cabos Mexico`,
        },
        {
          name: 'keywords',
          content: `Los Cabos Party Calendar. Upcoming Events @ Los Cabos Mexico, events, calendar, clubs, party agenda, dj agenda`,
        },
        {
          property: 'og:title',
          content: `Los Cabos · Parties & Events Calendar · Tickets & Reservation`,
        },
        {
          property: 'og:url',
          href: 'https://www.cabo.party/los-cabos-mexico-event-calendar',
        },
        {
          property: 'og:description',
          content: `Cabo Party Calendar. Upcoming Events @ Los Cabos Mexico.`,
        },
        { property: 'og:type', content: 'article' },
      ],
    };
  },
  components: {
    HeadImage,
    MiniEventCard,
    VueWhatsappWidget,
    DynamicLinks,
  },
  computed: {
    incomingParties: {
      get() {
        return this.$store.state.calendar;
      },
    },
    links: {
      get() {
        return this.$store.state.links || [];
      },
    },
    photos: {
      get() {
        return this.incomingParties.map((x) => x.metadata.main_image.imgix_url);
      },
    },
    photoCaptions: {
      get() {
        return this.incomingParties.map(
          (x) => x.title + ' - ' + x.metadata.date
        );
      },
    },
  },
  mounted: function () {
    moment.updateLocale('en', {
      week: {
        dow: 1, // Monday is the first day of the week.
      },
    });
    if (this.$route.query.compact) {
      this.compact = true;
    }
    this.getPeriod('this_week', true);
    this.$store.dispatch('getLinks', {slug: 'los-cabos-mexico-event-calendar'});
    this.$store.dispatch('getAllParties').then((_x) => {
      this.getPeriod(this.$route.query.period || 'this_week', true);
    });
    setTimeout(() => {
      this.showPalms = true;
    }, 3000);
  },
  methods: {
    goTo: function goTo(page) {
      this.$router.push({ path: page, replace: true });
    },
    onQuickReserveClick() {
      this.$gtag.event('reservations', {
        value: 20,
        event_category: 'reservations',
        event_label: 'PartySupport' });
      this.$rollbar.info('PartySupport');
    },
    getPeriod: function getPeriod(period, silent) {
      let label = '';
      if (period === 'today') {
        label = 'Today';
        this.getThisPeriod('day', label);
      }
      if (period === 'tomorrow') {
        label = 'Tomorrow';
        this.getNextPeriod('day', label);
      }
      if (period === 'this_week') {
        label = 'This Week';
        this.getThisPeriod('week', label);
      }
      if (period === 'next_week') {
        label = 'Next Week';
        this.getNextPeriod('week', label);
      }

      if (period === 'this_month') {
        label = 'This Month';
        this.getThisPeriod('month', label);
      }
      if (period === 'next_month') {
        label = 'Next Month';
        this.getNextPeriod('month', label);
      }
      if (period === 'this_year') {
        label = 'This Year';
        this.getThisPeriod('year', label);
      }
      if (period === 'nye_2024') {
        label = 'NYE 2024';
        this.getNextPeriod('year', label);
      }
      if (!silent) {
        this.notifyChange('Showing events for ' + label);
        // this.$rollbar.info('Calendar: ' + label);
      }
      this.addHashToLocation(period, this.compact);
    },

    getThisPeriod: function getThisPeriod(unitOfTime, label) {
      let parties = this.incomingParties;

      this.period = unitOfTime;
      this.periodLabel = label;
      this.periodParties = [...parties].filter((x) =>
        this.isIncomingParty(unitOfTime, x)
      );
      let _periodDates = {};
      this.periodPartiesDates = [];
      this.periodParties.forEach((y) => {
        if (_periodDates[y.metadata.date]) {
          this.periodPartiesDates.push(null);
        } else {
          _periodDates[y.metadata.date] = true;
          this.periodPartiesDates.push(y.metadata.date);
        }
      });
    },
    getNextPeriod: function getNextPeriod(unitOfTime, label) {
      let parties = this.incomingParties;
      this.period = unitOfTime;
      this.periodLabel = label;

      this.periodParties = [...parties].filter((x) =>
        this.isIncomingNext(unitOfTime, x)
      );
      let _periodDates = {};
      this.periodPartiesDates = [];
      this.periodParties.forEach((y) => {
        if (_periodDates[y.metadata.date]) {
          this.periodPartiesDates.push(null);
        } else {
          _periodDates[y.metadata.date] = true;
          this.periodPartiesDates.push(y.metadata.date);
        }
      });
      this.period = unitOfTime;
    },
    addHashToLocation(params, compact) {
      let path = this.$route.path + '?period=' + encodeURIComponent(params);
      if (compact) {
        path += '&compact=true';
      }
      history.pushState({}, null, path);
    },
    notifyChange: function notifyChange(change) {
      this.$notify({
        type: 'primary',
        title: 'Calendar',
        message: `${change}`,
        icon: 'tim-icons icon-bell-55',
      });
    },

    isIncomingParty: function isIncomingParty(unitOfTime, x) {
      return (
        moment()
          .startOf(unitOfTime)
          .isSame(x.metadata.date + 'T' + x.metadata.start_time, unitOfTime) &&
        (x.slug.match(/cabo/) || x.metadata.magicEvent)
      );
    },

    isIncomingNext: function isIncomingNext(unitOfTime, x) {
      return (
        moment()
          .add(1, unitOfTime)
          .startOf(unitOfTime)
          .isSame(x.metadata.date + ' ' + x.metadata.start_time, unitOfTime) &&
        (x.slug.match(/cabo/) || x.metadata.magicEvent)
      );
    },
    getDay: function getYear(date) {
      return moment(date, true).format('ddd');
    },
    getDay2: function getDay2(date) {
      return moment(date, true).format('DD');
    },
    getDay3: function getDay3(date) {
      return moment(date, true).format('MMM');
    },
    googleCalendar() {
      this.$rollbar.info(`Google Calendar Open`);
      this.$router.push({path: '/los-cabos-calendar', replace: true});
    },
  },
};
</script>
